import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Switch } from 'antd';
import { useTranslation } from '../../../hooks/useTranslation';

import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import licenseService from '../../../services/LicenseService';
import countryService from '../../../services/CountryService';
import coursesService from '../../../services/CoursesService';
 
const Courses = () => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [modal, setModal] = useState(false);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    price: '',
    description: '',
    mf: '',
    license: '',
    notes: '',
    image: null,
    country: '',
    active: true
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        title: '',
        price: '',
        description: '',
        mf: '',
        license: '',
        notes: '',
        image: null,
        country: '',
        active: true
      });
    }
  };

  const getCourses = async () => {
    try {
      const response = await coursesService.getAll();
      setCourses(response);
    } catch (error) {
      console.error(t('Error fetching courses:'), error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error(t('Error fetching master franchisors:'), error);
    }
  };

  const getLicenses = async () => {
    try {
      const response = await licenseService.getAll();
      setLicenses(response);
    } catch (error) {
      console.error(t('Error fetching licenses:'), error);
    }
  };

  const getCountries = async () => {
    try {
      const response = await countryService.getAll();
      setCountries(response);
    } catch (error) {
      console.error(t('Error fetching countries:'), error);
    }
  };

  useEffect(() => {
    getCourses();
    getMasterFranchisors();
    getLicenses();
    getCountries();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'file' ? event.target.files[0] : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    //eslint-disable-next-line
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    
    try {
      if (formData._id) {
        await coursesService.updateCourse(formData._id, formDataToSend);
      } else {
        await coursesService.createCourse(formDataToSend);
      }
      getCourses();
      toggleModal();
    } catch (error) {
      console.error(t('Error saving course:'), error);
    }
  };

  const handleEdit = (course) => {
    setFormData({
      _id: course._id,
      title: course.title,
      price: course.price,
      description: course.description,
      mf: course.mf,
      license: course.license,
      notes: course.notes,
      country: course.country,
      active: course.active
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this course?'))) {
      try {
        await coursesService.deleteCourse(id);
        getCourses();
      } catch (error) {
        console.error(t('Error deleting course:'), error);
      }
    }
  };

  const handleToggleActive = async (id, active) => {
    try {
      await coursesService.updateCourse(id, { active });
      getCourses();
    } catch (error) {
      console.error(t('Error toggling course status:'), error);
    }
  };

  const columns = [
    {
      Header: t('Name'),
      accessor: 'name',
      Cell: ({ value }) => t(value)
    },
    {
      Header: t('Country'),
      accessor: 'country',
      Cell: ({ value }) => {
        const country = countries.find(c => c._id === value);
        return country ? t(country.name) : t('N/A');
      }
    },
    {
      Header: t('Price'),
      id: 'price',
      accessor: (row) => { 
        const currencie = countries.find(c => c._id === row.country)?.currencie;
        return t('{{price}} {{currency}}', { price: row.price, currency: currencie });
      }
    },
    {
      Header: t('Status'),
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? t('Active') : t('Inactive')}
        </Badge>
      )
    },
    {
      Header: t('Actions'),
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>{t('Edit')}</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>{t('Delete')}</Button>
          {' '}
          <Switch
            checked={original.active}
            onChange={() => handleToggleActive(original._id, !original.active)}
          />
        </>
      )
    }
  ];

  return (
    <>
      <ComponentCard title={t('Courses ({{count}})', { count: courses.length ?? 0 })}>
        <Button color="primary" onClick={toggleModal} style={{marginBottom: '10px'}}>{t('Add New Course')}</Button>
        <ReactTable
          columns={columns}
          data={courses}
          filterable
          defaultPageSize={10}
          className="-striped -highlight myTable"
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? t('Edit Course') : t('Add New Course')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="title">{t('Title')}</Label>
              <Input type="text" name="title" id="title" value={formData.title} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="price">{t('Price')}</Label>
              <Input type="number" name="price" id="price" value={formData.price} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t('Description')}</Label>
              <Input type="textarea" name="description" id="description" value={formData.description} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="mf">{t('MF')}</Label>
              <Input type="select" name="mf" id="mf" value={formData.mf} onChange={handleInputChange} required>
                <option value="">{t('Select Master Franchisor')}</option>
                {masterFranchisors.map(mf => (
                  <option key={mf._id} value={mf._id}>{mf.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="license">{t('License')}</Label>
              <Input type="select" name="license" id="license" value={formData.license} onChange={handleInputChange}>
                <option value="">{t('Please select')}</option>
                {licenses.map(license => (
                  <option key={license._id} value={license._id}>{license.title}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="country">{t('Country')}</Label>
              <Input type="select" name="country" id="country" value={formData.country} onChange={handleInputChange} required>
                <option value="">{t('Select Country')}</option>
                {countries.map(country => (
                  <option key={country._id} value={country._id}>{country.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="notes">{t('Notes')}</Label>
              <Input type="textarea" name="notes" id="notes" value={formData.notes} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="image">{t('Image')}</Label>
              <Input type="file" name="image" id="image" onChange={handleInputChange} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">{t('Save')}</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>{t('Close')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Courses;