
import fetchRequest from '../auth/FetchInterceptorRequests'

const coursesService = {}

coursesService.coursesWithStudents = function () {
	return fetchRequest({
		url: `/digital/courseswithstudents`,
		method: 'get'
	})
}

coursesService.courseWithStudents = function (id) {
	return fetchRequest({
		url: `/digital/courseswithstudents/${id}`,
		method: 'get'
	})
}

coursesService.getAll = function () {
    return fetchRequest({
        url: '/digital/courses',
        method: 'get'
    })
}

export default coursesService;